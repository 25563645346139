














import { Component, Watch, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { REQUEST_ERROR } from './router';
import { LoadingSpinner } from '@nimiq/vue-components';

import '@nimiq/style/nimiq-style.min.css';
import '@nimiq/vue-components/dist/NimiqVueComponents.css';

@Component({components: {LoadingSpinner}})
export default class App extends Vue {
    @State('isRequestLoaded') private isRequestLoaded!: boolean;

    public async created() {
        await this.$store.dispatch('initWallets');
        this.$rpc.start();
    }

    private get isLoaded() {
        return this.isRequestLoaded
            || this.$route.name === REQUEST_ERROR;
    }
}
